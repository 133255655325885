import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import DayDate from "./DatePicker";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  autocomplete: {
    margin: "15px"
  },
  button: {
    margin: "15px",
    width: "250px",
    backgroundColor: "#48678a"
  }
}));

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  flex-direction: column;
`;

const BtnRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
const providerTheme = createMuiTheme({
  direction: "rtl",
  typography: {
    fontFamily: ["Cairo", "Arial", "sans-serif"].join(",")
  }
});
function Controls(props) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={providerTheme}>
      <Root>
        <Autocomplete
          className={classes.autocomplete}
          id="targets"
          value={props.values.selectedTargetObject}
          options={props.lists.targetObjects}
          onChange={props.functions.onChangeTargetObjects}
          getOptionLabel={option => option.caption}
          style={{ width: 250 }}
          renderInput={params => (
            <TextField {...params} label="نوع الهدف" variant="outlined" />
          )}
        />
        {props.values.selectedTargetObject.name == "unit" ? (
          <Autocomplete
            className={classes.autocomplete}
            id="units"
            value={
              props.values.selectedUnit
                ? props.values.selectedUnit
                : props.lists.units[0]
            }
            options={props.lists.units}
            onChange={props.functions.onUnitChange}
            getOptionLabel={option => option.$$user_name}
            style={{ width: 250 }}
            renderInput={params => (
              <TextField {...params} label="القطار" variant="outlined" />
            )}
          />
        ) : (
          <Autocomplete
            className={classes.autocomplete}
            id="groups"
            value={
              props.values.selectedGroup
                ? props.values.selectedGroup
                : props.lists.groups[0]
            }
            options={props.lists.groups}
            onChange={props.functions.onGroupChange}
            getOptionLabel={option => option.$$user_name}
            style={{ width: 250 }}
            renderInput={params => (
              <TextField {...params} label="اسم المجموعة" variant="outlined" />
            )}
          />
        )}
        <DayDate
          className={classes.pickers}
          style={{
            padding: "15px"
          }}
          title="اليوم"
          selectedDate={props.values.selectedDayDate}
          handleDateChange={props.functions.onDayDateChange}
        />
        <Autocomplete
          className={classes.autocomplete}
          id="fromPoints"
          value={props.values.selectedFromStation}
          options={props.lists.fromList}
          onChange={props.functions.onFromStationChange}
          getOptionLabel={option => option.caption}
          style={{ width: 250 }}
          renderInput={params => (
            <TextField {...params} label="محطة الانطلاق" variant="outlined" />
          )}
        />
        <Autocomplete
          className={classes.autocomplete}
          id="toPoints"
          value={props.values.selectedToStation}
          options={props.lists.toList}
          onChange={props.functions.onToStationChange}
          getOptionLabel={option => option.caption}
          style={{ width: 250 }}
          renderInput={params => (
            <TextField {...params} label="محطة الوصول" variant="outlined" />
          )}
        />
        <FormControlLabel
          control={
            <Switch
              checked={props.values.customColor}
              onChange={props.functions.onCustomColorChange}
              name="customColor"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          }
          label="الوان عشوائية؟"
        />
        <BtnRoot>
          <Button
            onClick={props.functions.execute}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            تنفيذ
          </Button>
          {props.values.yDirection == "up" ? (
            <IconButton
              onClick={props.functions.onDirectionChange}
              aria-label=""
            >
              <ArrowUpwardIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={props.functions.onDirectionChange}
              aria-label=""
            >
              <ArrowDownwardIcon />
            </IconButton>
          )}
        </BtnRoot>
      </Root>
    </ThemeProvider>
  );
}

export default Controls;
