import React from "react";
import ReactApexChart from "react-apexcharts";

let routesList = require("../files/lists.json").st;

const days = [
  "الاحد",
  "الاثنين",
  "الثلاثاء",
  "الاربعاء",
  "الخميس",
  "الجمعة",
  "السبت"
];
class LChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      series: props.dat.data.dataset //jsonData.chart //props.dat.data.dataset
    };
  }

  render() {
    // console.log("chart obj is");
    // console.log(this.props.dat.data.dataset);
    return (
      <div id="chart">
        <ReactApexChart
          options={{
            title: {
              text:
                " مخطط سير القطارات " +
                "(" +
                this.props.dat.values.selectedFromStation.caption +
                " - " +
                this.props.dat.values.selectedToStation.caption +
                ")" +
                " ليوم " +
                days[this.props.dat.values.selectedDayDate.getDay()] +
                " " +
                this.formatDate(this.props.dat.values.selectedDayDate),
              align: "center",
              margin: 15,
              offsetX: 0,
              offsetY: 0,
              floating: true,
              style: {
                fontSize: "18px",
                fontWeight: "bold",
                fontFamily: "Cairo",
                color: "#263238"
              }
            },
            colors: this.props.dat.lists.colors,
            chart: {
              animations: {
                enabled: false
              },
              locales: [
                {
                  name: "ar",
                  options: {
                    toolbar: {
                      exportToPNG: "حفظ كـ صورة",

                      menu: "القائمة",
                      selection: "تحديد منطقة",
                      selectionZoom: "تكبير المنطقة المحددة",
                      zoomIn: "تكبير",
                      zoomOut: "تصغير",
                      pan: "تحكم",
                      reset: "اعدادات الافتراضية"
                    }
                  }
                }
              ],
              defaultLocale: "ar"
            },
            annotations: {
              points: this.props.dat.lists.points
            },
            stroke: {
              curve: "straight"
            },
            stroke: {
              width: 4
            },
            grid: {
              show: true,
              padding: {
                right: 30,
                left: 20
              },
              xaxis: {
                lines: {
                  show: true
                }
              }
            },
            markers: {
              size: 5
            },

            //   labels:[1574680846000,1574684446000,1574684386000], // x data
            xaxis: {
              type: "datetime",
              min: new Date(new Date().setHours(3, 0, 0, 0)).getTime(), // start date
              max: new Date(new Date().setHours(26, 59, 59, 999)).getTime() // end date
            },
            yaxis: {
              reversed: this.props.dat.values.yDirection == "up" ? false : true,
              tickAmount: this.props.dat.data.chartConfig.max - 1,
              min: this.props.dat.data.chartConfig.min,
              max: this.props.dat.data.chartConfig.max,
              labels: {
                minWidth: "150px",
                style: {
                  color: "#000000",
                  fontSize: "14px",
                  fontFamily: "Cairo, sans-serif",
                  cssClass: "apexcharts-yaxis-label"
                },
                offsetX: 20,
                show: true,

                formatter: value => {
                  if (value < 0) {
                    // console.log("out of range");
                    return "Error0";
                  }
                  if (typeof value === "undefined") {
                    //alert(value)
                    return "Error1";
                  }

                  let obj =
                    routesList[this.props.dat.data.selectedArrName][value - 1];
                  //  console.log(jsonData.st[this.props.dat.data.selectedArrName][value-1])
                  if (!obj)
                    return value + " - " + this.props.dat.data.selectedArrName;
                  return obj.name + "/" + obj.km;
                }
              }
            },
            tooltip: {
              x: {
                format: "HH:mm:ss"
              }
            },
            legend: {
              show: true,
              showForSingleSeries: true
            }
          }}
          series={this.props.dat.data.dataset /*this.props.dat.data.dataset*/}
          type="line"
          height="750"
        />
      </div>
    );
  }
  formatDate(date) {
    if (date) {
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      let d = date.getDate();
      return y + "/" + m + "/" + d;
    }
    return "";
  }
}

export default LChart;
