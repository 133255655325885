import React from "react";
import moment from "moment-timezone";
import NavSide from "./components/sideNav";
import ProgressBar from "./components/pb";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";

import "../src/pbStyle.css";

const queryString = require("query-string");

const routesList = require("../src/files/lists.json").st;
const zones = require("../src/files/stations.json").stationsArr;

/* global wialon */

const groupReport = "avl_unit_group";
const unitReport = "avl_unit";
const zoneGroupToFind = "المحطات";

// Main app class
const mainRoutes = require("./files/lists.json").main_routes;
const targetObjects = require("./files/lists.json").target_objects;

const defaultSelectedArr = "baghdad_om-kaser";

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Cairo", "Arial", "sans-serif"].join(",")
  },
  palette: {
    primary: {
      main: "#48678a"
    }
  }
});
const UPDATE_INTERVAL = 120000; // 2 minutes
class App extends React.Component {
  constructor() {
    super();
    this.state = {
      // demo token
      loginMode: "authHash",
      isTried: false,
      token: "",
      sessionID: "",
      dataset: [],
      resultData: [], // col of response
      points: [],
      addedUnits: [],
      selectedArrName: defaultSelectedArr,
      isAuthorized: false,
      units: [],
      groups: [],
      reports: [],
      routes: [],
      selectedTargetObject: targetObjects[1], // unit is default value
      resources: [],
      selectedDayDate: new Date(),
      mainRoutes: mainRoutes,
      fromList: mainRoutes,
      toList: mainRoutes[0].end_points,
      selectedFromStation: mainRoutes[0],
      selectedToStation: mainRoutes[0].end_points[0],
      targetObjects: targetObjects,
      exeReportConfig: {
        res: {},
        groupReport: {}, // put report when we found it in res iteration
        unitReport: {},
        unitGroup: {},
        unit: {},
        interval: {
          from: -1,
          to: -1,
          flags: wialon.item.MReport.intervalFlag.absolute
        },
        type: "avl_unit_group"
      },
      executing: true,
      mode: "select",
      zones: [],
      chartConfig: {
        min: 1,
        max: routesList[defaultSelectedArr].length
      },
      chartData: [],
      randomColor: false,
      colors: [],
      colorsObject: [],
      yDirection: mainRoutes[0].end_points[0].direction, // default,
      timerID: 0
    };

    this.onChangeTargetObjects = this.onChangeTargetObjects.bind(this);
    this.onDayDateSelect = this.onDayDateSelect.bind(this);
    this.onFromStationChange = this.onFromStationChange.bind(this);
    this.onToStationChange = this.onToStationChange.bind(this);
    this.onUnitChange = this.onUnitChange.bind(this);
    this.onGroupChange = this.onGroupChange.bind(this);
    this.onCustomColorChange = this.onCustomColorChange.bind(this);
    this.onDirectionChange = this.onDirectionChange.bind(this);
    this.execute = this.execute.bind(this);
  }

  async componentDidMount() {
    // const script = document.createElement("script");
    // script.src = "https://hst-api.wialon.com/wsdk/script/wialon.js";
    // script.async = true;
    // document.body.appendChild(script);
  }
  loginFunction = async code => {
    if (code) {
      alert(wialon.core.Errors.getErrorText(code));
      window.location.href = "https://tracking.wenkgps.com";
      return;
    }

    await this.setState({
      isAuthorized: true,
      isTried: true
    });
    await this.init2();
  };
  componentWillUnmount() {
    if (this.state.timerID > 0) {
      clearInterval(this.state.timerID);
    }
  }
  onDirectionChange(e) {
    this.setState({
      ...this.state,
      yDirection: this.state.yDirection == "up" ? "down" : "up"
    });
  }
  async onCustomColorChange(e) {
    if (e) {
      await this.setState({
        ...this.state,
        randomColor: e.target.checked,
        executing: true
      });
      this.buildChartObj3(this.state.resultData);
    }
  }
  onUnitChange(e, value) {
    if (value) {
      let that = this;
      this.setState({
        exeReportConfig: {
          ...that.state.exeReportConfig,
          unit: value
        }
      });
    }
  }
  onGroupChange(e, value) {
    if (value) {
      let that = this;
      this.setState({
        exeReportConfig: {
          ...that.state.exeReportConfig,
          unitGroup: value
        }
      });
    }
  }

  async init2() {
    const sess = wialon.core.Session.getInstance(); // get instance of current Session
    // flags to specify what kind of data should be returned
    let res_flags =
      wialon.item.Item.dataFlag.base | wialon.item.Resource.dataFlag.reports;

    res_flags = 1060865; //
    sess.loadLibrary("itemCustomFields");
    sess.loadLibrary("resourceReports");
    sess.loadLibrary("resourceZoneGroups");
    sess.loadLibrary("resourceZones");

    let that = this;
    sess.updateDataFlags(
      // load items to current session
      [
        { type: "type", data: "avl_resource", flags: res_flags, mode: 0 },
        { type: "type", data: "avl_unit_group", flags: 1 + 8, mode: 0 },
        { type: "type", data: "avl_unit", flags: 1 + 8, mode: 0 }
      ], // Items specification
      async function(code) {
        await that.setup(sess, code);
      }
    );
    await this.setState({
      sessionID: sess
    });
  }

  render() {
    const { isAuthorized, units } = this.state;

    if (this.state.loginMode === "authHash") {
      // console.log("hash link");
      // console.log(this.props);
      let hash = queryString.parse(this.props.location.search).authHash;

      if (!hash) {
        window.location.href = "https://tracking.wenkgps.com";
        return;
      }
      if (hash && hash.length === 32 && !this.state.isTried) {
        this.auth1(hash);
      } else {
        // console.log('invalid checking with hash '+ctx.value.isTried)
      }
    } else {
      if (!this.state.isTried) {
        this.auth1("");
      } else {
        // console.log('invalid checking with token '+ctx.value.isTried)
      }
    }
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          {!isAuthorized ? (
            <div className="cen">
              <ProgressBar className="cen-item" color="secondary" />
            </div>
          ) : (
            <div>
              {this.state.executing ? (
                <div className="cen">
                  <ProgressBar className="cen-item" />
                </div>
              ) : !this.state.dataset ? (
                <div className="cen">
                  <ProgressBar className="cen-item" />
                </div>
              ) : (
                <NavSide
                  values={{
                    selectedTargetObject: this.state.selectedTargetObject,
                    selectedDayDate: this.state.selectedDayDate,
                    selectedFromStation: this.state.selectedFromStation,
                    selectedToStation: this.state.selectedToStation,
                    selectedGroup: this.state.exeReportConfig.unitGroup,
                    selectedUnit: this.state.exeReportConfig.unit,
                    customColor: this.state.randomColor,
                    yDirection: this.state.yDirection
                  }}
                  lists={{
                    fromList: this.state.fromList,
                    toList: this.state.toList,
                    units: this.state.units,
                    groups: this.state.groups,
                    routes: this.state.routes,
                    targetObjects: this.state.targetObjects,
                    points: this.state.points,
                    colors: this.state.colors
                  }}
                  functions={{
                    onChangeTargetObjects: this.onChangeTargetObjects,
                    onGroupChange: this.onGroupChange,
                    onUnitChange: this.onUnitChange,
                    onDayDateChange: this.onDayDateSelect,
                    onFromStationChange: this.onFromStationChange,
                    onToStationChange: this.onToStationChange,
                    execute: this.execute,
                    onCustomColorChange: this.onCustomColorChange,
                    onDirectionChange: this.onDirectionChange
                  }}
                  zones={zones}
                  data={this.state}
                  conf={{
                    yMax: zones.length > 0 ? zones.length - 1 : 0
                  }}
                ></NavSide>
              )}
            </div>
          )}
        </div>
      </ThemeProvider>
    );
  }
  async auth1(e) {
    this.auth(e);
  }
  async auth(hashOrToken) {
    wialon.core.Session.getInstance().initSession("https://hst-api.wialon.com");

    if (this.state.loginMode == "token") {
      const token = this.state.token;

      wialon.core.Session.getInstance().loginToken(
        token,
        "", // try to login
        this.loginFunction
      );
    } else {
      let hash = queryString.parse(this.props.location.search).authHash;
      // console.log("hash is");
      // console.log(hash);
      wialon.core.Session.getInstance().loginAuthHash(
        hash,
        "", // try to login
        this.loginFunction
      );
    }
    // // Try to login when component mount
    // if (this.state.loginMode === 'token') {
    //   // console.log('mode : T')
    //   this.loginWithToken()
    // }
    // if (this.state.loginMode === 'authHash') {
    //   // console.log('mode : H')
    //   this.loginWithHash(hashOrToken)
    // }
  }
  onDayDateSelect(e) {
    if (e)
      this.setState({
        selectedDayDate: e
      });
  }
  async setup(sess, code) {
    // updateDataFlags callback
    if (code) {
      alert(wialon.core.Errors.getErrorText(code));
      return;
    } // exit if error code

    await this.collectResources(sess);
    await this.findReports();
    await this.findStationGroup();
    let zonesArr = await this.findZones();
    await this.setState({ zones: zonesArr });
    await this.executeReport1(sess);
  }

  async collectResources(sess) {
    const res = sess.getItems("avl_resource");
    if (!res || !res.length) {
      alert("resources not found");
      return;
    }

    // get loaded 'avl_unit's items
    const units = sess.getItems("avl_unit");
    if (!units || !units.length) {
      alert("Units not found");
      return;
    } // check if units found
    units.sort(function(a, b) {
      if (a.$$user_name > b.$$user_name) return 1;
      if (a.$$user_name < b.$$user_name) return -1;
      return 0;
    });
    const unitGroupsArr = sess.getItems("avl_unit_group");

    if (!unitGroupsArr || !unitGroupsArr.length) {
      alert("groups not found");
      return;
    }
    await this.setState({
      resources: res,
      units: units,
      groups: unitGroupsArr
    });
  }

  async findReports() {
    let isGroupReportFound = false;
    let isUnitReportFound = false;
    let tempExeReport = {
      res: {},
      groupReport: {}, // put report when we found it in res iteration
      unitReport: {},
      unitGroup: {},
      unit: {},
      interval: {
        from: -1,
        to: -1,
        flags: wialon.item.MReport.intervalFlag.absolute
      },
      type: groupReport
    };
    for (var i in this.state.resources) {
      if (this.state.resources[i].hasOwnProperty("$$user_reports")) {
        if (isGroupReportFound && isUnitReportFound) {
          break;
        }

        var rep = this.state.resources[i].getReports();
        for (var j in rep) {
          if (
            rep[j].ct === "avl_unit_group" &&
            rep[j].n === "ENTRANCE TO STATION - GROUPS"
          ) {
            tempExeReport.groupReport = rep[j];
            isGroupReportFound = true;
            tempExeReport.res = this.state.resources[i];
            tempExeReport.unit = this.state.units[0];
            tempExeReport.unitGroup = this.state.groups[0]; //. set default

            tempExeReport.interval = {
              from: 1574542800,
              to: -1,
              flags: wialon.item.MReport.intervalFlag.absolute
            };
          }
          if (
            rep[j].ct === "avl_unit" &&
            rep[j].n === "ENTRANCE TO STATION - UNITS"
          ) {
            tempExeReport.unitReport = rep[j];
            isUnitReportFound = true;

            tempExeReport.res = this.state.resources[i];
            tempExeReport.unit = this.state.units[0];
            tempExeReport.unitGroup = this.state.groups[0]; //. set default
            tempExeReport.interval = {
              from: 1574542800,
              to: 0,
              flags: wialon.item.MReport.intervalFlag.absolute
            };
          }
          if (isGroupReportFound && isUnitReportFound) {
            break;
          }
        }
      }
    }
    await this.setState({ exeReportConfig: tempExeReport });
    return tempExeReport;
  }

  findStationGroup() {
    let stationsGroup;
    if (!this.state.exeReportConfig.res.hasOwnProperty("$$user_zonesGroups")) {
      alert("zones group not found");
      return stationsGroup;
    }
    // now get geo group
    var isGroupFound = false;
    for (var i in this.state.exeReportConfig.res.$$user_zonesGroups) {
      if (
        this.state.exeReportConfig.res.$$user_zonesGroups[i].n ===
        zoneGroupToFind
      ) {
        stationsGroup = this.state.exeReportConfig.res.$$user_zonesGroups[i].n;
        isGroupFound = true;
        break;
      }
    }
    return stationsGroup;
  }
  findZones() {
    if (!this.state.exeReportConfig.res.hasOwnProperty("$$user_zones")) {
      alert("zones not found");
      return [];
    }
    return this.state.exeReportConfig.res.$$user_zones;
  }

  async executeReport1(sess) {
    let executing = false;
    let that = this;
    if (this.state.exeReportConfig.res.hasOwnProperty("_id")) {
      // setLocalTime Zone
      var localeObj = { flags: 0, formatDate: "%25Y-%25m-%25E %25H:%25M:%25S" };
      sess
        .getRenderer()
        .setLocale(134228528, "en", localeObj, async function(code) {
          if (code) {
            return;
          }
          // execute now
          executing = true;
          //selectedLineID = 0;
          // await that.setState({
          //   executing: true
          // });
          await that.executeReport2(sess);
        });
    } else {
      alert("no resource found");
      return;
    }
  }
  async executeReport2(sess) {
    var res3 = sess.getItem(this.state.exeReportConfig.res._id);

    var temp = {};

    var objectID = 0;
    if (this.state.exeReportConfig.type == groupReport) {
      objectID = this.state.exeReportConfig.unitGroup._id;
      temp = res3.getReport(this.state.exeReportConfig.groupReport.id);
    }
    if (this.state.exeReportConfig.type == unitReport) {
      objectID = this.state.exeReportConfig.unit._id;
      temp = res3.getReport(this.state.exeReportConfig.groupReport.id);
    }
    let fromUnix = 0;
    let toUnix = 0;

    if (this.state.mode == "today") {
      fromUnix = moment()
        .startOf("day")
        .unix();

      toUnix = Math.round(new Date().getTime() / 1000);
    }
    if (this.state.mode == "select") {
      let dayDate = this.state.selectedDayDate;
      let startOfDay = new Date(
        dayDate.getFullYear(),
        dayDate.getMonth(),
        dayDate.getDate()
      );
      fromUnix = Math.floor(startOfDay.getTime() / 1000);
      toUnix = fromUnix + (23 * 3600 + 59 * 60);
    }
    await this.setState(prevState => ({
      ...prevState,
      exeReportConfig: {
        ...prevState.exeReportConfig,
        interval: {
          ...prevState.exeReportConfig.interval,
          from: fromUnix,
          to: toUnix
        }
      }
    }));

    let that = this;

    res3.execReport(
      temp,
      objectID,
      0,
      this.state.exeReportConfig.interval, // execute selected report
      async function(code, data) {
        // execReport template

        if (code) {
          return;
        } // exit if error code
        if (!data.getTables().length) {
          alert("No data for selected train!");
          await that.setState({ executing: false });

          return;
        } else {
          let table = data.getTables()[0];

          // now get the result rows
          var configObj = {
            type: "range",
            data: { from: 0, to: table.rows, level: 5, flat: 0, rawValues: 0 }
          };

          var isDataGot = false;
          data.selectRows(0, configObj, function(code2, col) {
            if (code2) {
              that.setState({ executing: false });
              return;
            }

            if (col) {
              that.buildChartObj3(col);
              // console.log("col data is");
              // console.log(col);
            }
            that.setState({ executing: false, resultData: col });
            if (that.state.timerID == 0) {
              setTimeout(() => that.updateSeries(), UPDATE_INTERVAL);
            }
          });
        }
      }
    );
  }
  updateSeries() {
    let that = this;
    let sessionID = this.state.sessionID;
    this.timerID = setInterval(() => {
      that.executeReport1(sessionID);
    }, UPDATE_INTERVAL);
  }

  createCollection(col) {
    let newCol = [];
    for (let i = 0; i < col.length; i++) {
      let obj = {
        id: col[i].c[1], // vehicle name,
        data: []
      };
      for (let j = 0; j < col[i].r.length; j++) {
        if (col[i].r[j].c[3] != "Unknown") {
          let zoneName = col[i].r[j].c[2]; // name of zone that vehicle  entered
          obj.data.push({
            x: col[i].r[j].c[3].t,
            y: this.getIDOfZone(zoneName)
          });
        }
        if (col[i].r[j].c[4] != "Unknown") {
          let zoneName = col[i].r[j].c[2]; // name of zone that vehicle  entered
          obj.data.push({
            x: col[i].r[j].c[4].t,
            y: this.getIDOfZone(zoneName)
          });
        }
      }
      newCol.push(obj);
    }
    return newCol;
  }

  getIDOfZone(zoneName) {
    let index = zones.findIndex(function(item) {
      return item.name == zoneName;
    });
    if (index > -1) {
      return zones[index].myIndex;
    }
    return -1;
  }
  requestFunction() {
    setInterval(this.timer, 1000);
  }

  async buildChartObj3(reportData) {
    let colorsTemp = [];
    let colorsObjectTemp = [];
    let finalColors = [];
    let datasets = []; // empty old data
    // init temp obj

    var obj = {
      name: "",
      data: []
    };
    let pointsTemp = [];
    let addedUnitsList = [];
    // console.log("Report Data");
    // console.log(reportData);
    for (
      var i = 0;
      i < reportData.length;
      i++ // main loop to iterate through units -> first level
    ) {
      // take unit name and put it in label
      //var colr=getRandomColor();
      var colr = "#000000";
      var unitName = reportData[i].c[1];
      // check custom color mode
      if (this.state.randomColor) {
        if (colorsObjectTemp.length > 0) {
          var object = colorsObjectTemp.find(function(e) {
            if (e.unitName === unitName) return e;
            return null;
          });

          if (object != null) colr = object.cval;
          if (object == null) {
            colr = this.getRandomColor();
            colorsObjectTemp.push({ unitName: unitName, cval: colr });
          }
        } else {
          colr = this.getRandomColor();
          colorsObjectTemp.push({ unitName: unitName, cval: colr });
        }
      } else {
        let unit = this.state.units.find(function(item) {
          return item.$$user_name == unitName;
        });
        if (unit) {
          let cv = Object.entries(unit.$$user_customFields).find(function(
            cvTemp
          ) {
            return cvTemp[1].n == "chart_color";
          });
          if (cv) {
            colr = cv[1].v.trim();
          }
        }
      }
      obj = {
        name: reportData[i].c[1],
        data: []
      };

      var time = "";
      let outTime = "";
      var index = -1;
      var name = "";

      for (
        var j = 0;
        j < reportData[i].r.length;
        j++ // lopp through details rows
      ) {
        time = reportData[i].r[j].c[3].t;
        if (typeof reportData[i].r[j].c[4].t == "undefined") {
          outTime = time;
        } else {
          outTime = reportData[i].r[j].c[4].t;
        }
        name = reportData[i].r[j].c[2];

        index = this.getZoneId(routesList[this.state.selectedArrName], name);
        // console.log("index is " + index);
        if (index != -1) {
          // arrival time
          let timeArr = time.split(" ");
          let ts = Number(
            moment(timeArr[1], "HH:mm:ss", "Asia/Baghdad")
              .add(3, "hours")
              .valueOf()
          );
          // obj.data.push({ x: ts, y: index });
          obj.data.push([ts, index]);
          timeArr = outTime.split(" ");
          let tsOut = Number(
            moment(timeArr[1], "HH:mm:ss", "Asia/Baghdad")
              .add(3, "hours")
              .valueOf()
          );

          obj.data.push([tsOut, index]);
          // check points existing
          let ind = addedUnitsList.findIndex(function(item) {
            return item == obj.name;
          });

          if (ind == -1 && reportData[i].r.length > 1) {
            // then push annotation
            // cache unit
            addedUnitsList.push(obj.name);
            finalColors.push(colr);

            let xTemp = Number(
              moment(timeArr[1], "HH:mm:ss", "Asia/Baghdad")
                .add(3, "hours")
                .valueOf()
            );
            // check if there is the same point x,y already inserted
            // let indexFound = pointsTemp.findIndex(function(item) {
            //   return item.y == index;
            // });
            // let yTemp = index;
            // if (indexFound > -1) {
            //   // then shift y a little
            //   if (yTemp < this.state.chartConfig.max) yTemp = yTemp + 1;
            //   else yTemp = yTemp - 1;
            // }

            pointsTemp.push({
              x: xTemp,
              y: index,
              marker: {
                size: 0,
                fillColor: "#fff",
                strokeColor: "#2698FF",
                radius: 2
              },
              label: {
                borderColor: "#000",
                offsetY: 0,
                style: {
                  color: "#fff",
                  background: colr
                },

                text: obj.name
              }
            });
          }
        }
      }
      // console.log("added object");
      // console.log(obj);
      if (obj.data.length > 2) datasets.push(obj);
    }
    // console.log("points is");
    // console.log(pointsTemp);
    await this.setState({
      dataset: datasets,
      addedUnits: addedUnitsList,
      executing: false,
      points: pointsTemp,
      colors: finalColors
    });
    // console.log("in build function");
    // console.log(this.state);
  }
  getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  getZoneId(zones, n) {
    //var arr=Object.values(zones);
    // console.log("zone name to find is");
    // console.log(n);
    // console.log(zones);
    try {
      var zone = zones.find(x => x.name == n);
      return zone.myIndex;
    } catch (e) {
      return -1;
    }
  }
  onChangeTargetObjects(e, value) {
    if (value) {
      let that = this;
      this.setState({
        selectedTargetObject: value,
        exeReportConfig: {
          ...that.state.exeReportConfig,
          type: value.type
        }
      });
    }
  }

  getInitFromDate() {
    var my_date = new Date();
    var first_date = new Date(
      my_date.getFullYear(),
      my_date.getMonth(),
      my_date.getDate() - 1
    );
    return first_date;
  }
  onFromStationChange(e, value) {
    if (value) {
      // console.log(value);
      let routeName = value.name + "_" + value.end_points[0].name;
      // console.log(routeName);
      let isFound = false;
      let arrayTemp = Object.entries(routesList);
      for (let i = 0; i < arrayTemp.length; i++) {
        // console.log("s" + arrayTemp[i][0]);
        if (arrayTemp[i][0] == routeName) {
          isFound = true;
          break;
        }
      }
      let max = -1;
      if (isFound) max = routesList[routeName].length;
      else {
        routeName = value.end_points[0].name + "_" + value.name;
        max = routesList[routeName].length;
      }
      // console.log("new route name is ");
      // console.log(routeName);
      // console.log("max is " + max);
      this.setState(
        {
          toList: value.end_points,
          selectedFromStation: value,
          selectedToStation: value.end_points[0],
          selectedArrName: routeName,
          yDirection: value.end_points[0].direction,
          chartConfig: {
            ...this.state.chartConfig,
            max: max
          }
        },
        async () => {
          await this.buildChartObj3(this.state.resultData);
          // console.log(this.state);
        }
      );
    }
  }
  onToStationChange(e, value) {
    let routeName = this.state.selectedFromStation.name + "_" + value.name;
    // console.log(routeName);
    let max = routesList[routeName].length;

    if (value) {
      this.setState(
        {
          ...this.state,
          selectedToStation: value,
          selectedArrName: routeName,
          yDirection: value.direction,
          chartConfig: {
            ...this.state.chartConfig,
            max: max
          }
        },
        () => this.buildChartObj3(this.state.resultData)
      );
    }
  }
  execute(e) {
    this.setState({
      ...this.state,
      executing: true
    });
    let sessionID = this.state.sessionID;
    this.executeReport1(sessionID);
  }
}
export default App;
