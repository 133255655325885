import React from "react";
import { BrowserRouter, Route, Switch, Router } from "react-router-dom";
import "./App.css";
import Main from "./Main";

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BrowserRouter basename="/">
        <Switch>
          <Route path="/start" exact component={Main} />
          <Route path="/" component={Main} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
